import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

class InfraArticle extends React.Component {
    render = () => (
        <React.Fragment>
            <article className="manifest__article show-more-article" style={ this.articleStyle() }>

                <h2 className="manifest__article-heading">SYSADMIN</h2>

                <h3 className="manifest__article-sub-heading">SERVER ADMINISTRATION</h3>
                <p className="manifest__para">
                </p>

                <h4 className="manifest__article-sub-heading">SERVERS INSTALLATION AND CYBERSECURITY.</h4>
                <p className="manifest__para">
                    LINUX, CENTOS, UBUNTU, NGINX, APACHE, ...<br />
                    MICROSOFT, IIS, ...
                </p>

                <h4 className="manifest__article-sub-heading">ACCOUNTS ADMINISTRATION.</h4>
                <p className="manifest__para">
                    WHM / Cpanel & Plesk .
                </p>

                <h4 className="manifest__article-sub-heading">MONITORING & QUALIMETRIE.</h4>
                <p className="manifest__para">

                </p>
                
            </article>
            <button className="show-more-button" onClick={ (e) => this.toggleShowMore() }> { this.state.buttonValue } </button>
        </React.Fragment>
    )

    constructor(props){
        super(props)
    
        this.state = {
            showMore: true,
            buttonValue: "",
            showMoreText: "Afficher plus",
            showLessText: "Afficher moins"
        }

    }

    componentDidMount = () => {
        this.toggleShowMore()
    }

    toggleShowMore = () => {
        let {showMore, showMoreText, showLessText} = this.state
        this.setState({
            showMore: !showMore,
            buttonValue: !showMore == false ? showMoreText : showLessText
        })
    }

    articleStyle = () => {
        if (this.state.showMore) return { height: "auto" }
        else return { height: "300px" }
    }
}

export default InfraArticle
