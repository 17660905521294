import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

class DevArticle extends React.Component {
    render = () => (
        <React.Fragment>
            <article className="manifest__article show-more-article" style={ this.articleStyle() }>
                
                <h2 className="manifest__article-heading">DEVELOPPEMENT ET MAINTENANCE</h2>

                <h3 className="manifest__article-sub-heading">TECHNOLOGIE - INTERFACES FRONT</h3>
                <p className="manifest__para">
                </p>
                

                <h4 className="manifest__article-sub-heading">HTML XML CSS JS.</h4>
                <p className="manifest__para">
                    Principaux langages web Front.
                </p>

                <h4 className="manifest__article-sub-heading">MOBILE.</h4>
                <p className="manifest__para">
                    Xcode - Interface Builder<br />
                    Android Studio<br />
                    ReactNative
                </p>

                <h4 className="manifest__article-sub-heading">Framework Front-End.</h4>
                <p className="manifest__para">
                    VueJS<br />
                    ReactJS<br />
                    AngularJS
                </p>
                <hr />

                <h3 className="manifest__article-sub-heading" >TECHNOLOGIE - BACK END</h3>
                <p className="manifest__para">
                Nous concevons, transformons et optimisons comment vos serveurs & applications raisonnent et échangent avec d'autres serveurs, clients légers, applets et applications mobiles. Votre portefeuille d'applications au cœur de nos préoccupations !
                </p>


                <h4 className="manifest__article-sub-heading">SWIFT.</h4>
                <p className="manifest__para">
                    Swift est un langage de programmation compilé multi-paradigmes
                </p>

                <h4 className="manifest__article-sub-heading">NodeJs.</h4>
                <p className="manifest__para">
                    Node.js est une plateforme logicielle libre et événementielle en JavaScript orientée vers les applications réseau qui doivent pouvoir monter en charge.
                </p>

                <h4 className="manifest__article-sub-heading">PHP.</h4>
                <p className="manifest__para">
                    Hypertext Preprocessor, plus connu sous son sigle PHP, est un langage de programmation libre, principalement utilisé pour produire des pages Web dynamiques via un serveur HTTP
                </p>

                <h4 className="manifest__article-sub-heading">Pyton.</h4>
                <p className="manifest__para">
                    Python est un langage de programmation objet interprété, multi-paradigme et multiplateformes. <br />
                    Il favorise la programmation impérative structurée, fonctionnelle et orientée objet.
                </p>

                <hr />

                <h3 className="manifest__article-sub-heading" >TECHNOLOGIE - FRAMEWORK BACK END</h3>
                <p className="manifest__para">
                </p>


                <h4 className="manifest__article-sub-heading">VAPOR 3 (Swift).</h4>
                <p className="manifest__para">
                    Vapor est un framework web open source écrit en Swift. Il peut être utilisé pour créer des API RESTful, des applications Web et des applications en temps réel à l'aide de WebSockets.
                </p>

                <h4 className="manifest__article-sub-heading">EXPRESSJS (NodeJs).</h4>
                <p className="manifest__para">
                    Express.js est un framework pour construire des applications web basées sur Node.js. C'est de fait le framework standard pour le développement de serveur en Node.js.
                </p>

                <h4 className="manifest__article-sub-heading">LARAVEL (PHP).</h4>
                <p className="manifest__para">
                    Laravel est un framework web open-source écrit en PHP respectant le principe modèle-vue-contrôleur et entièrement développé en programmation orientée objet. Laravel est distribué sous licence MIT, avec ses sources hébergées sur GitHub.
                </p>

                <h4 className="manifest__article-sub-heading">DJANGO (Pyton).</h4>
                <p className="manifest__para">
                    Django est un cadre de développement web source ouverte en Python. Il a pour but de rendre le développement web 2.0 simple et rapide.
                </p>

                <hr />

                <h3 className="manifest__article-sub-heading" >TECHNOLOGIE - HYBRID APPLICATIONS</h3>
                <p className="manifest__para">
                </p>


                <h4 className="manifest__article-sub-heading">Développement de Cross Platform Hybrid.</h4>
                <p className="manifest__para">
                    Une base de code unique sur plusieurs plates-formes : iOS, Android, Windows Phone, etc...
                </p>
            </article>
            <button className="show-more-button" onClick={ (e) => this.toggleShowMore() }> { this.state.buttonValue } </button>
        </React.Fragment>
    )

    constructor(props){
        super(props)
    
        this.state = {
            showMore: true,
            buttonValue: "",
            showMoreText: "Afficher plus",
            showLessText: "Afficher moins"
        }

    }

    componentDidMount = () => {
        this.toggleShowMore()
    }

    toggleShowMore = () => {
        let {showMore, showMoreText, showLessText} = this.state
        this.setState({
            showMore: !showMore,
            buttonValue: !showMore == false ? showMoreText : showLessText
        })
    }

    articleStyle = () => {
        if (this.state.showMore) return { height: "auto" }
        else return { height: "300px" }
    }

}

export default DevArticle
