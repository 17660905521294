import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../components/layout"
import VideoCover from "../components/video-cover"
import Realisation from "../components/realisation"
import ToggleBox from "../components/toggle-box"
import DemandCallToAction from "../components/demand-call-to-action"
import SEO from "../components/seo"
import helpers from "../scripts/helpers"
//PAGE COMPONNENTS
import GestionProjetArticle from "../components/article-gestion-projet"
import DevArticle from "../components/article-dev"
import InfraArticle from "../components/article-infra"

class Developpement extends React.Component {
  render = () => (
    
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <main className="scroll-jacker">
          
          <VideoCover
              title="Développement, maintenance & infra"
              videoUrl="/video/positiveemotiondev.mp4" 
          />

          <main className="manifest">

            <section className="manifest__section manifest__section--services">
                <h2 className="manifest__heading">UTOPIANS DEV TEAM</h2>
                <p className="manifest__tagline">
                    Nous aimons nos projets comme des passionnés et réalisons notre mission comme des professionnels.
                    <br />
                </p>
            </section>

            <section className="manifest__section manifest__section--services">
              <h2 className=""> > GESTION DE PROJETS</h2>
              <header className="manifest__services-header manifest__services-header--toggled">
              </header>
              <GestionProjetArticle />
            </section>

            <section className="manifest__section manifest__section--services">
              <h2 className=""> > DEVELOPPEMENT ET MAINTENANCE</h2>
              <header className="manifest__services-header manifest__services-header--toggled"></header>
              <DevArticle />
            </section>

            <section className="manifest__section manifest__section--services">
              <h2 className=""> > SYSADMIN</h2>
              <header className="manifest__services-header manifest__services-header--toggled"></header>
              <InfraArticle />
            </section>

          </main>

          <Realisation />

        </main>
      </Layout>
    
  )

  componentDidMount = () => { 
  }


}

export default Developpement
