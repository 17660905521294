import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

class GestionProjetArticle extends React.Component {
    render = () => (
        <React.Fragment>
            <article className="manifest__article show-more-article" style={ this.articleStyle() }>

                <h2 className="manifest__article-heading">GESTION DE PROJETS</h2>

                <h3 className="manifest__article-sub-heading"></h3>
                <p className="manifest__para">
                    Comme de véritables pionniers, nous ne suivons pas un chemin simplement parce que c'est facile.<br />
                    L'efficacité et l'efficience sont des aspects clés de nos approches de projets. Quelle que soit la tâche à
                    accomplir, <strong>cela peut se faire.</strong>
                </p>
                <h3 className="manifest__article-sub-heading">Listen & share (l'écoute et l'échange) :</h3>
                <p className="manifest__para">
                    • Premier contact<br />
                    • Identification des besoins <br />
                    • Identification des objectives
                </p>
                <h3 className="manifest__article-sub-heading">Une offre sur mesure :</h3>
                <p className="manifest__para">
                    • Pragmatisation <br />
                    • Moyens<br />
                    • Délais<br />
                </p>
                <h3 className="manifest__article-sub-heading">Design visant la performance et l'interaction :</h3>
                <p className="manifest__para">
                    • Architecture<br />
                    • Choix de Technologie Backend<br />
                    • Choix de Technologie Front<br />
                    • Choix de la méthode gestion de projet (Scrum, Kanban, Lean)<br />
                    • Mise en place du product Backlog<br />
                    • Dossier de conception<br />
                </p>
                <h3 className="manifest__article-sub-heading">Réalisation des maquettes d'interfaces graphiques :</h3>
                <p className="manifest__para">
                    • UX - Interactive Wireframes<br />
                    • UI - Interfaces Utilisateurs<br />
                </p>
                <h3 className="manifest__article-sub-heading">Exposition :</h3>
                <p className="manifest__para">
                    • Exposition des maquettes aux utilisateurs finaux<br />
                    • Collecte des retours et remarques sur les story-boards et ergonomie de l'application<br />
                </p>
                <h3 className="manifest__article-sub-heading">Fine-tuning des maquettes d'interfaces graphiques :</h3>
                <p className="manifest__para">
                    • UX - Interactive Wireframes<br />
                    • UI - Interfaces Utilisateurs<br />
                </p>
                <h3 className="manifest__article-sub-heading">Préparation de l'infrastructure/Devops :</h3>
                <p className="manifest__para">
                    • Mise en place de l'environnement de DEV<br />
                    • Mise en place de l'environnement de Recette<br />
                    • Mise en place de l'environnement de Production<br />
                    • Mise en place du mécanisme de la continious delivery<br />
                    • Exposition du lien de la recette<br />
                </p>
                <h3 className="manifest__article-sub-heading">Développement :</h3>
                <p className="manifest__para">
                    • Planification de livraison des product Backlogs selon la méthode gestion de projet (Waterfall ou Agile)<br />
                    • Planification des Itérations<br />
                    • Développement -> Test ou TDD (développement piloté par les tests)<br />
                </p>
                <h3 className="manifest__article-sub-heading"></h3>
            </article>
            <button className="show-more-button" onClick={ (e) => this.toggleShowMore() }> { this.state.buttonValue } </button>
        </React.Fragment>
    )

    constructor(props){
        super(props)
    
        this.state = {
            showMore: true,
            buttonValue: "",
            showMoreText: "Afficher plus",
            showLessText: "Afficher moins"
        }

    }

    componentDidMount = () => {
        this.toggleShowMore()
    }

    toggleShowMore = () => {
        let {showMore, showMoreText, showLessText} = this.state
        this.setState({
            showMore: !showMore,
            buttonValue: !showMore == false ? showMoreText : showLessText
        })
    }

    articleStyle = () => {
        if (this.state.showMore) return { height: "auto" }
        else return { height: "300px" }
    }

}

export default GestionProjetArticle
